import { template as template_877dc09263f6484f8be2c6eb2f84bcc2 } from "@ember/template-compiler";
const FKLabel = template_877dc09263f6484f8be2c6eb2f84bcc2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
