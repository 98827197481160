import { template as template_6df48947211e4470b2a4205e8ee5eb62 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_6df48947211e4470b2a4205e8ee5eb62(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
