import { template as template_314608b0240143ac8d24b70cdff350bb } from "@ember/template-compiler";
const SidebarSectionMessage = template_314608b0240143ac8d24b70cdff350bb(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
